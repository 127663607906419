<template>
    <div>
        <div class="mb-3">
            <label for="osRefreshTime" class="form-label"
                >Rafraîchissement auto de la liste des interventions (mn) (> ou = à 5mn)</label
            >
            <input type="number" class="form-control" id="osRefreshTime" v-model="osRefreshTime" min="5" step="1" />
        </div>
        <div v-if="refreshTimeHasChanged" class="text-end mt-5">
            <div class="btn-group" role="group">
                <button class="btn btn-primary" @click="osRefreshTime = getOsRefreshTimeData">Annuler</button>
                <button class="btn btn-warning" @click="setOsRefreshTimeData(osRefreshTime)">
                    Enregistrer la modification
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'OsRefreshTime',
    data: () => ({
        osRefreshTimeMemory: undefined,
    }),
    computed: {
        osRefreshTime: {
            get() {
                return this.osRefreshTimeMemory || this.getOsRefreshTimeData
            },
            set(value) {
                this.osRefreshTimeMemory = parseInt(value)
            },
        },
        ...mapGetters('osRefreshTime', ['getOsRefreshTimeData']),
        refreshTimeHasChanged() {
            return parseInt(this.osRefreshTime) !== this.getOsRefreshTimeData
        },
    },
    methods: {
        ...mapActions('osRefreshTime', ['setOsRefreshTimeData']),
    },
}
</script>

<style scoped></style>
